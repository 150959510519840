import {ProjectDto, ProjectFiltersDto} from "../../generated/api";
import {useEffect, useState} from "react";
import {useApi} from "../ApiProvider";
import {TableWithCopy} from "../TableWithCopy";
import Column from "antd/es/table/Column";
import {ProjectPopup} from "./ProjectPopup";
import {numberSorter, textSorter} from "../../utils/utils";
import {formatCost} from "./Projects";

interface ProjectListProps {
    filters: ProjectFiltersDto
    refreshKey: number
    withBudgets?: boolean
}

export function ProjectList(props: Readonly<ProjectListProps>) {
    const [data, setData] = useState<ProjectDto[]>();
    const [selectedProjectId, setSelectedProjectId] = useState<{fullProjectNum: string, year: number}>();
    const api=useApi();

    useEffect(()=>{
        api.listProjects({
            ...props.filters
        }).then(response=>{
            setData(response.data)
        })
    }, [props.filters, props.refreshKey, api])

    function openDetails(r: ProjectDto) {
        setSelectedProjectId({fullProjectNum: r.fullProjectNum, year: r.year})
    }

    return <div style={{flex:1, display: 'flex', flexDirection: 'column'}}>
                <ProjectPopup id={selectedProjectId} onClose={() => {
                    setSelectedProjectId(undefined)
                }}/>
            <TableWithCopy size={"small"} scroll={{y: '100%'}} className={"people-publications-table"} dataSource={data}
                           rowKey={'id'}
                           style={{flex: 1}} pagination={false}>
                <Column<ProjectDto> title={"Core #"}
                                    dataIndex={'coreProjectNum'}
                                    sorter={textSorter('coreProjectNum')}
                                    width={"10%"}
                />
                <Column<ProjectDto> title={"Full #"}
                                    dataIndex={"fullProjectNum"}
                                    sorter={textSorter('fullProjectNum')}
                                    width={"10%"}
                                    render={(v, r)=><a href={'#'} onClick={()=>openDetails(r)}>{v}</a>}
                />
                <Column<ProjectDto> title={"Title"}
                                    dataIndex={'title'}
                                    sorter={textSorter('title')}
                                    width={"40%"}
                />
                <Column<ProjectDto> title={"Year"}
                                    dataIndex={"year"}
                                    sorter={numberSorter('year')}
                                    width={"10%"}
                />
                <Column<ProjectDto> title={"PI Names"}
                                    dataIndex={"piNames"}
                                    width={"20%"}
                />
                {props.withBudgets && <Column<ProjectDto> title={"Budget"}
                                                          dataIndex={"totalCost"}
                                                          sorter={numberSorter('totalCost')}
                                                          width={"10%"}
                                                          render={v=>formatCost(v)}
                />}
            </TableWithCopy>
        </div>
}