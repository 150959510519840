import * as React from "react";
import {useState} from "react";
import {Button, Checkbox, Flex, Popover} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {Ranks} from "../People";

interface RankTagProps {
    closeSelectDropdown: ()=>void
    onRemove: (event?: React.MouseEvent<HTMLElement, MouseEvent>)=>void
    value: string
    setValue: (value: string)=>void
    rankOpen: boolean
    setRankOpen: (open: boolean)=>void
}

export function RankTag(props: RankTagProps) {
    const [rank, setRank] = useState<string[]>(parseValue(props.value))
    function parseValue(value: string) {
        if (value.startsWith("rank:")) {
            const val = props.value.split(':', 2)[1].trim()
            return val.split(',').map(x=>x.trim()).filter(x=>x!=="")
        }
        return []
    }

    function getButtonTitle() {
        if (rank.length===0) return "Choose Academic Ranks";
        return rank.map(x=>Ranks[x]).filter(x=>x!=undefined).join(", ");
    }

    function refresh(value: string[]) {
        props.setValue(value.join(","))
    }

    return <div className={'tag'} onMouseDown={e=>e.stopPropagation()}>
        <Popover content={
            <Flex vertical={true}>
                {Object.entries(Ranks).map(kv =>
                    <Checkbox key={kv[0]} checked={rank.includes(kv[0])} onChange={e=>{
                        if (e.target.checked) {
                            setRank(old=>{
                                const n = [...old, kv[0]]
                                refresh(n)
                                return n
                            })
                        } else {
                            setRank(old=>{
                                const n = old.filter(x=>x!=kv[0])
                                refresh(n)
                                return n
                            })
                        }
                    }}>{kv[1]}</Checkbox>
                )}
            </Flex>
            }
                 style={{width: 400}}
                 arrow={false}
                 trigger={'click'}
                 placement={"bottomLeft"}
                 destroyTooltipOnHide={true}
                 align={{offset: [0, 6]}}
                 onOpenChange={(open)=>{
                     if (open) {
                         props.closeSelectDropdown()
                     }
                     props.setRankOpen(open)
                 }}
                 open={props.rankOpen}
        >
            <Button size={"small"} type={'link'}>{getButtonTitle()}</Button>
        </Popover>
        <Button type={'text'} size={"small"} className={'close-tag'} onClick={props.onRemove}><CloseOutlined/></Button>
    </div>
}

